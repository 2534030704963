.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.recipes {
  margin: auto;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.home {
  width: 100%;
  height: auto;
  background-color: #cb444a;
}

.homeButtons {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white;
  color: #cb444a;
  font-size: 24px;
  padding: 16px 30px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.homeButtons:hover {
  background-color: #cb444a;
  color: white;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.homeButtons2 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white;
  color: #cb444a;
  font-size: 24px;
  padding: 16px 30px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.homeButtons2:hover {
  background-color: #cb444a;
  color: white;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.groupButtons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.title {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  color: white;
  font-weight: bold;
  font-size: 40px;
}

.titleRed {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: white;
  font-weight: bold;
  font-size: 40px;
}

.pizza {
  position: absolute;
  margin-top: 10px;
}

.navElements {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  color: #cb444a;
  margin: 10px;
  font-size: 20px;
  font-weight: bold;
}

.rotate {
  margin: auto;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

a:hover {
  color: #cb444a;
}
